import { lessonIsProcessing } from 'src/utils/lessonsUtils';
import LessonDetailProcessing from 'src/components/LessonDetail/LessonDetailProcessing';
import LessonDetailSmartbits from 'src/components/LessonDetail/LessonSmartbits/LessonDetailSmartbits';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { LessonSubject } from 'src/types/lesson.types';
import OldLessonDetailSmartbits from 'src/components/LessonDetail/LessonSmartbits/old/LessonDetailSmartbits';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { LessonContainerHeader } from 'src/components/LessonDetail/LessonContainer';
import { useTranslation } from 'react-i18next';

const LessonRevision = () => {
  const { lesson } = useLesson();
  const { t } = useTranslation();
  const isProcessing = lessonIsProcessing(lesson);

  if (isProcessing) {
    return <LessonDetailProcessing />;
  }

  if (
    [LessonSubject.spanish, LessonSubject.english].includes(
      lesson.subject_matter,
    )
  ) {
    return (
      <LayoutWithHeader
        header={<LessonContainerHeader title={t('lessons.details.revision')} />}
      >
        <OldLessonDetailSmartbits lessonId={lesson.id!} />
      </LayoutWithHeader>
    );
  }

  return <LessonDetailSmartbits />;
};

export default LessonRevision;

import { FC, type SVGProps } from 'react';
import { LessonQuiz, Question } from 'src/types/quiz.types';

export interface LessonSmartbitQuestion {
  id: number;
  lesson_smartbit_id: number;
  current_grade: number;
  question_payload: Question;
  user_flag: string;
  created_at?: string;
  updated_at?: string;
}

export interface SmartbitContent {
  type: string;
  objective: string;
  details: object;
  review_questions: { question: string; réponses: string[] }[];
}

export interface LessonSmartbit {
  id?: number;
  lesson_id: number;
  lesson_piece_id: number;
  smartbit_type: string;
  content: string[] | SmartbitContent;
  questions_generated: boolean;
  get_questions_job_id: string;
  created_at?: string;
  updated_at?: string;
}

export interface LessonPiece {
  id?: number;
  lesson_id: number;
  title: string;
  storage_reference: string;
  raw_text_content: string;
  preprocessed_text_content: string;
  post_process_completed: boolean;
  transcript_job_id?: string;
  get_smartbits_job_id?: string;
  post_process_failed: boolean;
  created_at?: string;
  updated_at?: string;
}

export enum LessonSubject {
  geography = 'geography',
  history = 'history',
  english = 'english',
  spanish = 'spanish',
  mathematics = 'mathematics',
  sciences = 'sciences',
  physics = 'physics',
}

export type LessonSubjectType = {
  key: LessonSubject;
  labelKey: string;
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  isBeta?: boolean;
};

export interface Lesson {
  id?: number;
  subject_matter: LessonSubject;
  title: string;
  to_revise: boolean;
  user_id?: number;
  latest_quiz?: LessonQuiz | null;
  lesson_pieces: LessonPiece[];
  is_test: boolean;
  created_at: string;
  updated_at?: string;
  evaluation_date?: string;
}

import i18n from 'src/modules/i18n';
import History from 'src/images/subjects/history.svg?react';
import SVT from 'src/images/subjects/svt.svg?react';
import Physics from 'src/images/subjects/physics.svg?react';
import Mathematics from 'src/images/subjects/mathematics.svg?react';
import Geography from 'src/images/subjects/geography.svg?react';
import Spanish from 'src/images/subjects/spanish.svg?react';
import English from 'src/images/subjects/english.svg?react';
import { LessonSubject, LessonSubjectType } from 'src/types/lesson.types';
import { MilestoneType } from 'src/types/milestone.types';

export const subjects: {
  [key in LessonSubject]: LessonSubjectType;
} = {
  [LessonSubject.geography]: {
    key: LessonSubject.geography,
    labelKey: 'subjects.geography.label',
    IconComponent: Geography,
  },
  [LessonSubject.history]: {
    key: LessonSubject.history,
    labelKey: 'subjects.history.label',
    IconComponent: History,
  },
  [LessonSubject.mathematics]: {
    key: LessonSubject.mathematics,
    labelKey: 'subjects.mathematics.label',
    IconComponent: Mathematics,
  },
  [LessonSubject.sciences]: {
    key: LessonSubject.sciences,
    labelKey: 'subjects.sciences.label',
    IconComponent: SVT,
  },
  [LessonSubject.physics]: {
    key: LessonSubject.physics,
    labelKey: 'subjects.physics.label',
    IconComponent: Physics,
  },
  [LessonSubject.english]: {
    key: LessonSubject.english,
    labelKey: 'subjects.english.label',
    IconComponent: English,
    isBeta: true,
  },
  [LessonSubject.spanish]: {
    key: LessonSubject.spanish,
    labelKey: 'subjects.spanish.label',
    IconComponent: Spanish,
  },
};

export const allSubjects = Object.values(subjects);
export const nonSpanishSubjects = allSubjects.filter(
  ({ key }) => key !== LessonSubject.spanish,
);

export const quizQuestionFlagOptions = (): Record<
  string,
  { label: string; value: string }
> => ({
  wrong_question: {
    label: i18n.t('quiz.questionFlagOptions.wrongQuestion'),
    value: 'wrong_question',
  },
  no_correct_answer: {
    label: i18n.t('quiz.questionFlagOptions.noCorrectAnswer'),
    value: 'no_correct_answer',
  },
  not_in_the_lesson: {
    label: i18n.t('quiz.questionFlagOptions.notInTheLesson'),
    value: 'not_in_the_lesson',
  },
  other: {
    label: i18n.t('quiz.questionFlagOptions.other'),
    value: 'other',
  },
});

export enum LocalStorageKey {
  STREAKS_COUNT = 'STREAKS_COUNT',
  MILESTONES = 'MILESTONES',
  FAMILY_USER_ID = 'familyUserId',
  LESSON_PIECE = 'LESSON_PIECE',
  XP_WON = 'XP_WON',
}

export const milestoneTriggers: Partial<Record<MilestoneType, number[]>> = {
  [MilestoneType.lessons]: [5, 10, 20, 30, 40, 50],
  [MilestoneType.quizzes]: [1, 10, 20],
  [MilestoneType.quiz_answers]: [10, 50, 100, 150, 200],
  [MilestoneType.geography]: [1],
  [MilestoneType.history]: [1],
  [MilestoneType.mathematics]: [1],
  [MilestoneType.sciences]: [1],
};

import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Button from 'src/components/common/designSystem/Button';

import { LessonSubject } from 'src/types/lesson.types';

const getErrorDescriptionKey = (subject: LessonSubject): string => {
  switch (subject) {
    case LessonSubject.geography:
    case LessonSubject.history:
    case LessonSubject.sciences:
    case LessonSubject.physics:
    case LessonSubject.mathematics:
      return 'lessons.piece.error.audio.description';
    case LessonSubject.english:
    case LessonSubject.spanish:
      return 'lessons.piece.error.photo.description';
    default:
      return 'lessons.piece.error.audio.description';
  }
};

const LessonPieceErrorPanel = ({
  isOpen,
  onClose,
  subject,
}: {
  isOpen: boolean;
  onClose?: () => void;
  subject: LessonSubject;
}) => {
  const { t } = useTranslation();

  return (
    <AutoFloatingPanel isOpen={isOpen} onClose={onClose}>
      <div className={'flex flex-col gap-5 p-6 text-center'}>
        <div className={'text-4xl'}>🤔</div>
        <div className={'font-bold text-balance text-xl'}>
          {t('lessons.piece.error.title')}
        </div>
        <div className={'text-base text-balance'}>
          {t(getErrorDescriptionKey(subject))}
        </div>
        <Button onClick={onClose} type={'default'}>
          {t('global.continue')}
        </Button>
      </div>
    </AutoFloatingPanel>
  );
};

export default LessonPieceErrorPanel;

import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import CheckIcon from 'src/images/check.svg?react';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { useNotification } from 'src/contexts/NotificationContext';
import { useNotificationShown } from 'src/queries/notifications';

const InAppQuizReadyNotification = ({
  notification,
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.QUIZ_READY;
  };
  onRead: () => Promise<void>;
}) => {
  const notificationShownRef = useRef<number>();
  const queryClient = useQueryClient();
  const params = notification.params as {
    lesson_piece_id: number;
    lesson_id: number;
    quiz_id: number;
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { show, close } = useNotification();
  const { mutateAsync: notificationShown } = useNotificationShown();

  useEffect(() => {
    queryClient.invalidateQueries(['lesson_pieces', params.lesson_piece_id]);
    queryClient.invalidateQueries(['lessons']);
    queryClient.invalidateQueries(['lessons', params.lesson_id]);
  }, [params.lesson_id, params.lesson_piece_id, queryClient]);

  const onStart = useCallback(async () => {
    await onRead();
    close(`quiz-ready-${params.lesson_id}`);
    navigate(`/lessons/${params.lesson_id}/quizzes/${params.quiz_id}`);
  }, [close, navigate, onRead, params.lesson_id, params.quiz_id]);

  const showNotification = useCallback(async () => {
    if (
      notificationShownRef.current === notification.id ||
      notification.read_at ||
      notification.shown_at
    ) {
      return;
    }
    notificationShownRef.current = notification.id;
    show({
      message: (
        <div className={'flex flex-row items-center justify-between w-full'}>
          <span className={'text-white font-bold text-base text-balance'}>
            {t('lessons.piece.quiz.title')}
          </span>
          <CheckIcon className={'fill-pink-300 w-5 h-auto'} />
        </div>
      ),
      duration: 5,
      onClick: onStart,
      placement: 'bottom',
      closable: false,
      className: 'bg-black text-white',
      props: {
        'data-testid': `toast-quiz-ready-${params.lesson_id}`,
      },
      key: `quiz-ready-${params.lesson_id}`,
    });
    await notificationShown(notification.id);
  }, [
    notification.id,
    notification.read_at,
    notification.shown_at,
    notificationShown,
    onStart,
    params.lesson_id,
    show,
    t,
  ]);

  useEffect(() => {
    showNotification();
  }, [showNotification]);

  return null;
};

export default InAppQuizReadyNotification;

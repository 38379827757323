import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import MonthlyOffer from 'src/components/Paywall/MonthlyOffer';
import { usePaywall } from 'src/contexts/PaywallContext';
import PaywallCheckIcon from 'src/components/Paywall/PaywallCheckIcon';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { PURCHASES_ERROR_CODE } from '@revenuecat/purchases-capacitor';
import { useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';

const PaywallScreenPay = ({ onSkip }: { onSkip?: () => void }) => {
  const { t } = useTranslation();
  const { subscribePremiumWithTrial, nbTrialDays } = usePaywall();
  const navigate = useNavigate();
  const [paying, setPaying] = useState(false);
  const { showError } = useNotification();

  const pay = async () => {
    try {
      setPaying(true);
      await subscribePremiumWithTrial();
    } catch (error: any) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      console.error(error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  return (
    <div className={'flex flex-col p-5 gap-6'}>
      <div
        className={'flex-1 flex justify-start items-center'}
        onClick={() => navigate(-1)}
      >
        <IconMdiChevronLeft fontSize={30} />
      </div>

      <div
        className={
          'font-extrabold text-xl text-structural-darkest text-left text-balance'
        }
      >
        <Trans i18nKey="paywall.pay.title">
          <span
            className={
              'bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
            }
          >
            Essai gratuit
          </span>{' '}
          de Baobab
        </Trans>
      </div>

      <div className={'flex flex-col gap-4 items-start'}>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PaywallCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t('paywall.pay.item1', { nbTrialDays })}
          </div>
        </div>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PaywallCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t('paywall.pay.item2')}
          </div>
        </div>
      </div>

      <MonthlyOffer />

      <div
        className={
          'flex flex-col gap-2 items-start text-base text-structural-darkest w-full'
        }
      >
        <div className={'flex flex-row justify-between items-center w-full'}>
          <div>
            {t('paywall.pay.paymentDate', {
              date: dayjs().add(14, 'day').format('DD MMMM YYYY'),
            })}
          </div>
          <div>{t('paywall.panel.price')}</div>
        </div>
        <div
          className={
            'flex flex-row justify-between items-center font-bold w-full'
          }
        >
          <div className={'flex flex-row items-center gap-1'}>
            <Trans i18nKey="paywall.pay.paymentToday" values={{ nbTrialDays }}>
              Dû aujourd’hui
              <span
                className={
                  'text-xs bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
                }
              >
                (14 jours gratuits)
              </span>
            </Trans>
          </div>
          <div>0 €</div>
        </div>
      </div>

      <div className={'flex flex-col w-full gap-2'}>
        <Button
          data-testid={'btn-pay-trial'}
          type={'primary'}
          onClick={pay}
          loading={paying}
        >
          {t('paywall.pay.button')}
        </Button>
        {onSkip ? (
          <Button data-testid={'btn-skip-trial'} type={'link'} onClick={onSkip}>
            {t('paywall.pay.buttonSkip')}
          </Button>
        ) : null}
      </div>

      <PaywallCGU />
    </div>
  );
};

export default PaywallScreenPay;

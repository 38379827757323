import { Experiment } from '@amplitude/experiment-js-client';

export const initFeatureFlags = () => {
  return Experiment.initializeWithAmplitudeAnalytics(
    import.meta.env.VITE_AMPLITUDE_API_KEY,
    {
      debug: import.meta.env.MODE === 'development',
    },
  );
};

import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { notification, type NotificationArgsProps } from 'antd';
import { type AxiosError, isAxiosError } from 'axios';
import './Notification.css';

type ErrorArgs =
  | (NotificationArgsProps & { error?: undefined })
  | (Omit<NotificationArgsProps, 'message'> & {
      error: unknown;
      message: ReactNode;
    });
type StaticFn = (args: NotificationArgsProps) => void;

export interface NotificationContextType {
  showSuccess: StaticFn;
  showInfo: StaticFn;
  showWarning: StaticFn;
  show: StaticFn;
  showError: (args: ErrorArgs) => void;
  close: (key: string) => void;
}

export const NotificationContext = createContext<NotificationContextType>({
  showSuccess: () => {
    throw new Error('Not implemented');
  },
  showInfo: () => {
    throw new Error('Not implemented');
  },
  showWarning: () => {
    throw new Error('Not implemented');
  },
  showError: () => {
    throw new Error('Not implemented');
  },
  show: () => {
    throw new Error('Not implemented');
  },
  close: () => {
    throw new Error('Not implemented');
  },
});

const NotificationContextProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const showError = useCallback(
    (args: ErrorArgs) => {
      if (args.error && isAxiosError(args.error)) {
        const error: AxiosError = args.error;
        api.error({
          props: args.props,
          message: args.message,
          description: t(
            `error.http.${error.response?.status ?? error.status}`,
            {
              defaultValue: t('error.http.default'),
            },
          ),
        });
        return;
      }

      api.error(args);
    },
    [api, t],
  );

  const close = useCallback(
    (key: string) => {
      api.destroy(key);
    },
    [api],
  );

  return (
    <NotificationContext.Provider
      value={{
        showSuccess: api.success,
        showInfo: api.info,
        showWarning: api.warning,
        showError,
        show: api.open,
        close,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};

export default NotificationContextProvider;
